import {
  Account,
  GoodsIssuedNotes,
  Auth,
  Documents, Pagination,
} from '@one/types'
import { mutations } from './mutations'
import getters from './getters'
import { actions } from './actions'
import { AccountState, AgreementsMapping, DocumentsPagePagination } from './types'
import Pager = Pagination.Pager
import GoodsReceivedNoteRestView = GoodsIssuedNotes.Responses.GoodsReceivedNoteRestView;

const state = (): AccountState => ({
  currentUser: null,
  agreements: {} as AgreementsMapping,
  client: {} as Account.App.Responses.ClientRestView,
  clientAddresses: {} as Pager<Account.App.Responses.Address>,
  clientUsers: {} as Pager<Account.App.Responses.User>,
  tradeCredit: {} as Account.App.Responses.TradeCredit,
  documents: {} as Documents.DocumentsMapping,
  authorities: {} as Auth.Roles.Authorities,
  roles: {} as Auth.Roles.RolesMapping,
  isWarehouseSelectionBlocked: false,
  registrationSettings: {} as Account.App.Responses.RegistrationSettings,
  documentsPage: {
    pageNumber: 1,
    pageSize: 10,
    search: null,
    startDate: null,
    endDate: null,
    paymentFilter: Documents.DocumentFilterEnum.ALL,
    sortingMode: null,
    documentTypes: [
      Documents.DocumentTypeEnum.INVOICE,
      Documents.DocumentTypeEnum.INVOICE_CORRECTION,
    ],
    sumsInvoice: null,
    allDocumentsSelected: false,
    fetching: false,
    page: 1,
    totalItems: 0,
    selectedDocuments: [],
    loadedPages: {},
  } as DocumentsPagePagination,
  goodsIssuedNotesPage: {
    pageNumber: 1,
    pageSize: 30,
    search: null,
    startDate: null,
    endDate: null,
    sortOrder: 'DESC',
  } as GoodsIssuedNotes.Backoffice.Responses.GoodsIssuedPagePagination,
  goodsIssuedNotes: {} as GoodsIssuedNotes.Responses.GoodsIssuedNotePaginated,
  goodsIssuedNote: {} as GoodsIssuedNotes.Responses.GoodsIssuedNote,
  gusData: {} as Account.App.Responses.BillingAddress,
  goodsReceivedNotesPage: {
    pageNumber: 1,
    pageSize: 30,
    search: null,
    startDate: null,
    endDate: null,
    sortOrder: 'DESC',
  } as GoodsIssuedNotes.Backoffice.Responses.GoodsIssuedPagePagination,
  goodsReceivedNotes: {} as Pagination.Pager<GoodsReceivedNoteRestView>,
  goodsReceivedNote: {} as GoodsIssuedNotes.Responses.GoodsReceivedNoteRestView,
})

export const namespaced: boolean = true

export { mutations, actions, getters, state }
