import {
 Component, namespace, Vue,
} from 'nuxt-property-decorator'
import { BindingHelpers } from 'vuex-class/lib/bindings'
import { MappedAttributeMultiCheckboxFilter, Products } from '@one/types'

const cms: BindingHelpers = namespace('cms')
const products = namespace('products')

interface DefaultSeoData {
  title: string,
  description: string,
}

export const DIVIDER = {
  DOT: ' \u2022 ',
  DASH: ' - ',
  COMMA: ', ',
  WHITESPACE: ' ',
  SEMICOLON: '; ',
}

// TODO: deprecated - use composables/useSeoMethods.ts
@Component
export default class SeoMethodsMixin extends Vue {
  @cms.Getter configuration: any
  @products.Getter getSelectedAttributes: any
  @products.Getter getFilter: any

  // eslint-disable-next-line max-len
  structuredSeoTitle(separator: string = DIVIDER.DASH, payload: Array<string | undefined | null> = []): string {
    return payload.filter(Boolean).join(`${separator}`)
  }

  get defaultSeoData(): DefaultSeoData {
    const { title, description } = this.configuration.seo
    return {
      title: title || this.$t('default_seo.title') || this.$tenantConfig.tenantKey,
      description: description || this.$t('default_seo.description') as string,
    }
  }

  get filtersSeo(): string | undefined {
    const selectedFilter: Array<MappedAttributeMultiCheckboxFilter> = Object.keys(this.getSelectedAttributes)
      .slice(0, 2).map((el: string) => this.getFilter(el))
    return this.structuredSeoTitle(DIVIDER.SEMICOLON, selectedFilter
      .map((el: MappedAttributeMultiCheckboxFilter) => `${el.name}: ${this.structuredSeoTitle(DIVIDER.COMMA, Object.keys(el.items)
      .filter((item: string) => el.items[item].selected)
      .map((item: string) => el.items[item].value))}`)) || undefined
  }

  get defaultSeoRobots(): string {
    return this.$tenantConfig.isDev ? 'noindex, nofollow' : 'index, follow'
  }

  robotsSeo(seo: Products.ProductSeo | undefined): string {
    const robots: Array<string> = []
    if (typeof (seo?.index) === 'boolean') {
      robots.push(seo?.index ? 'index' : 'noindex')
    }
    if (typeof (seo?.follow) === 'boolean') {
      robots.push(seo?.follow ? 'follow' : 'nofollow')
    }
    return robots.join(', ')
  }
}
