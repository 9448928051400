





























  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Watch } from 'nuxt-property-decorator'

  @Component
  export default class OneContentUnitInput extends Vue {
    name: string = 'one-content-unit-input'

    @Prop({
      required: true,
      type: String,
    })
    unit!: string

    @Prop({
      required: true,
      type: Number,
    })
    packingQuantity!: number

    @Prop({
      required: true,
      type: Number,
    })
    quantityInOrderUnit!: number

    @Prop({
      type: Boolean,
    })
    showControls?: boolean

    @Prop({
      type: Boolean,
      default: false,
      required: false,
    })
    disabled?: boolean

    quantityValue: number = 1

    onChange(value: number) {
      this.$emit('content-quantity-changed', value)
    }

    decrementValue() {
      if (this.quantityValue > this.packingQuantity) {
        this.onChange(this.quantityValue - this.packingQuantity)
      }
    }

    incrementValue() {
      this.onChange(this.quantityValue + this.packingQuantity)
    }

    get quantityValueComputed() {
      return this.quantityValue ? Number(this.quantityValue.toFixed(4)).toString() : undefined
    }

    @Watch('quantityInOrderUnit', { immediate: true })
    onOrderQuantityChanged() {
      this.quantityValue = this.quantityInOrderUnit * this.packingQuantity
    }
  }
