// REGISTER HERE GLOBAL COMPONENTS
import Vue from 'vue'
import OneAdditionalCostName from '~/components/atoms/costs/OneAdditionalCostName.vue'
import OneButton from '~/components/molecules/buttons/OneButton'
import OneHeader from '~/components/molecules/navigations/OneHeader'
import OneHiddenH1 from '~/components/atoms/tags/OneHiddenH1'
import OneNotificationBanner from '~/components/molecules/notifications/OneNotificationBanner'
import OneFormContainerTitle from '~/components/molecules/forms/OneFormContainerTitle'
import OneBaseHomePage from '~/components/molecules/bases/OneBaseHomePage'
import OneImage from '~/components/atoms/images/OneImage'
import OneFormContainerInput from '~/components/molecules/forms/OneFormContainerInput'
import OneFakeProductListView from '~/components/organisms/fake/OneFakeProductListView'
import OneFakeProductTileView from '~/components/organisms/fake/OneFakeProductTileView'
import OneFakeProductMinilistView from '~/components/organisms/fake/OneFakeProductMinilistView'
import OneProductDropdownAddToWishlist from '~/components/molecules/dropdowns/OneProductDropdownAddToWishlist'
import OneInputTooltip from '~/components/molecules/inputs/OneInputTooltip'
import OneContentUnitInput from '~/components/molecules/inputs/OneContentUnitInput'
import OneProductDropdownAddToCart from '~/components/molecules/dropdowns/OneProductDropdownAddToCart'
import OneIconLoading from '~/components/molecules/icons/OneIconLoading'
import OneStockStatusIcon from '~/components/molecules/icons/OneStockStatusIcon.vue'
import OneProductDataContainer from '~/components/organisms/product/OneProductDataContainer.vue'
import OneQuantityAndIntervalInfo from '~/components/molecules/tiles/OneQuantityAndIntervalInfo.vue'
import OneInputWithControls from '~/components/molecules/inputs/OneInputWithControls.vue'
import OneLink from '~/components/molecules/navigations/OneLink.vue'

Vue.component('OneAdditionalCostName', OneAdditionalCostName)
Vue.component('OneButton', OneButton)
Vue.component('OneHeader', OneHeader)
Vue.component('OneHiddenH1', OneHiddenH1)
Vue.component('OneNotificationBanner', OneNotificationBanner)
Vue.component('OneFormContainerTitle', OneFormContainerTitle)
Vue.component('OneBaseHomePage', OneBaseHomePage)
Vue.component('OneImage', OneImage)
Vue.component('OneFormContainerInput', OneFormContainerInput)
Vue.component('OneFakeProductListView', OneFakeProductListView)
Vue.component('OneFakeProductTileView', OneFakeProductTileView)
Vue.component('OneFakeProductMinilistView', OneFakeProductMinilistView)
Vue.component('OneProductDropdownAddToWishlist', OneProductDropdownAddToWishlist)
Vue.component('OneInputTooltip', OneInputTooltip)
Vue.component('OneContentUnitInput', OneContentUnitInput)
Vue.component('OneInputWithControls', OneInputWithControls)
Vue.component('OneProductDropdownAddToCart', OneProductDropdownAddToCart)
Vue.component('OneIconLoading', OneIconLoading)
Vue.component('OneStockStatusIcon', OneStockStatusIcon)
Vue.component('OneProductDataContainer', OneProductDataContainer)
Vue.component('OneQuantityAndIntervalInfo', OneQuantityAndIntervalInfo)
Vue.component('OneLink', OneLink)
